import { useRef } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function CardTable({ playerReferral, pending, usernameEvent, onChangeRowsPerPage }) {

    const columns = [
        {
            name: "Username",
            selector: (row, idx) => row.username,
            sortable: true,
        },
        {
            name: "First Deposit Date",
            selector: (row) => row.first_dp_date,
            sortable: true,
        },
        {
            name: "First Dp Amount",
            selector: (row) => 'Rp. ' + row.first_dp_amnt,
            sortable: true,
        },
        {
            name: "Last Login IP",
            selector: (row) => row.last_login_ip,
            sortable: true,
        },
        {
            name: "Bank Acc Name",
            selector: (row) => row.bank_acc_name,
            sortable: true,
        },
        {
            name: "Bank Acc Number",
            selector: (row) => row.bank_acc_num,
            sortable: true,
        },
    ];

    return (
        <div>
            <div>
                <Card className="mt-12">
                    <CardHeader color="blueGray" contentPosition="left">
                        <h2 className="text-white text-2xl">Player Referral First Deposit</h2>
                    </CardHeader>
                    <CardBody >
                    <DataTable
                        columns={columns}
                        data={playerReferral}
                        progressPending={pending}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        paginationRowsPerPageOptions={[10, 50, 100, 500]}
                        pagination
                    />
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}
