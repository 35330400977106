import React, { useState } from 'react'
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Select from 'react-select';
import Textarea from "@material-tailwind/react/Textarea";
import Swal from 'sweetalert2';
import axiosInstance from "../config/axios";

const option = [
    {
        label :"Inactive",
        value : 0
    },
    {
        label: 'Active',
        value: 1
    },
    {
        label: 'Locked',
        value: 2
    },
    {
        label: 'Banned',
        value: 3
    }
]

function PlayerStatus({ data }) {

    const [status, setStatus] = useState(data.stat)
    const [description, setDescription] = useState(data.deta)

    const submit = (event) => {

        const token = localStorage.getItem("access_token")
        const group = JSON.parse(localStorage.getItem("group"))
        delete data['stat']

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance("set_player_det", {
                    method: "POST",
                    data: {
                        ...data,
                        token: token,
                        group: group.wbid,
                        stat: status,
                        flnm: data.name,
                        emal: data.emai,
                        deta : description,
                        user_id: data.uid,

                    },
                }).then((res) => {
                    Swal.fire(
                        'Success!',
                        'Your data has been Updated',
                        'success'
                    )
                }).catch((err) => {
                    console.log(err)
                });

            }
        })

        event.preventDefault()
    }


    return (
        <>
            <Card className="mt-12">
                <CardHeader size="sm" color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Player Status</h2>
                </CardHeader>
                <CardBody>
                    <div className="grid grid-cols-1 gap-4 ">
                        <form onSubmit={submit}>
                            <div className="">
                                <Select options={option}
                                    defaultValue={() => {
                                        return option.find((opt) => opt.value === data.stat)

                                    }}
                                    onChange={(event) => {
                                        if(event.value === 0 ){
                                            event.value = "00"
                                        }
                                        setStatus(event.value)
                                    }}
                                />
                            </div>
                            <div>
                                <Button className="mt-2" size="sm">Save</Button>
                            </div>
                            <div className='mt-10'>
                                <Textarea
                                    color="lightBlue"
                                    size="regular"
                                    placeholder="System Information"
                                    outline={true}
                                    defaultValue={data.deta}
                                    onChange={(event)=>{
                                        setDescription(event.target.value)
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default PlayerStatus