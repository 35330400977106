import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
import Swal from "sweetalert2";
import axiosInstance from "../config/axios";
import { Link } from "react-router-dom";
import '../assets/styles/tabledepositrequest.css';
import Select from 'react-select';

export default function TableWithdrawRequset({
  data,
  pending,
  acceptEvent,
  declineEvent,
  filterBank
}) {
  const token = localStorage.getItem("access_token");

  const group = JSON.parse(localStorage.getItem("group"));

  const options = [
    { value: 'BCA', label: 'BCA' },
    { value: 'BNI', label: 'BNI' },
    { value: 'BRI', label: 'BRI' },
    { value: 'MANDIRI', label: 'MANDIRI' },
    { value: 'DANAMON', label: 'DANAMON' },
    { value: 'DANA', label: 'DANA' },
    { value: 'OVO', label: 'OVO' },
    { value: 'GOPAY', label: 'GOPAY' },
    { value: 'BANK JAGO', label: 'BANK JAGO' },
    { value: 'LINKAJA', label: 'LINKAJA' },
    { value: 'CIMB NIAGA', label: 'CIMB NIAGA' },
    { value: 'BSI', label: 'BSI' },
    { value: 'QRIS', label: 'QRIS' },
    { value: 'TELKOMSEL', label: 'TELKOMSEL' },
    { value: 'XL', label: 'XL' },
    { value: 'SEABANK', label: 'SEABANK' },
    { value: 'JENIUS', label: 'JENIUS' },
    { value: 'SAKUKU', label: 'SAKUKU' }
  ]

  const accept = (id) => {

    const data = {

      token: token,
      group: group.wbid,
      req_id: id
    }

    Swal.fire({

      title: 'Aceept This Withdraw',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        cancelButton: 'order-confirm-cancel',
        confirmButton: 'order-confirm-ok',
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(confirm => {

      if (confirm.isConfirmed) {

        axiosInstance.post('withdraw_accept', data ).then((res) => {

          if(res.data.code == 0) {

            Swal.fire('Accepted', 'Withdraw Request is Accept', 'success');
            acceptEvent();
          }else{

            Swal.fire('error', res.data.message, 'warning');
            acceptEvent();
          }
        }).catch((err) => {

          console.log(err);
          Swal.fire('Error', 'Check Your Access And Internet Conection', 'info');
        })
      }
    })
  }

  const decline = (event, id) => {

    const data = {
      token: token,
      group: group.wbid,
      req_id: id,
    };

    Swal.fire({
      title: "Admin note",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Decline",
      showLoaderOnConfirm: true,
      preConfirm: (note) => {
        axiosInstance
          .post("withdraw_decline", { admin_note: note, ...data })
          .then((res) => {

            if(res.data.code == 0) {

              Swal.fire(res.data.message, "Success", "success");
              declineEvent();
            }else{

              Swal.fire('error', res.data.message, 'warning');
              declineEvent();
            }
          })
          .catch((err) => {

            console.log(err);
            Swal.fire('Error', 'Check Your Access And Internet Conection', 'info');
          });

        event.preventDefault();
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    event.preventDefault();
  };

  const columns = [
    {
      name: "#",
      width: "80px",
      selector: (row, index) => row.id,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.user,
      sortable: true,
    },
    {
      name: "Akun",
      selector: (row) => (
        <div>
          <p>{row.rban + " " + row.rnum + " - " + row.rnam}</p>
        </div>
      ),
      width: "280px",
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => <tt>{row.amou}</tt>,
      sortable: true,
      width: '170px',
    },
    {
      name: "Current Balance",
      selector: (row) => <tt>{row.cbal}</tt>,
      sortable: true,
      width: "170px",
    },
    {
      name: "Request Date",
      selector: (row) => row.rdat,
      sortable: true,
      width:'180px'
    },
    {
      name: "",
      selector: (row) => (
        <div className="flex gap-4">
          <Button
            color="green"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
            onClick={() => {

              accept(row.id);
            }}
          >
            Accept
          </Button>
          <Button
            color="deepOrange"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
            onClick={(event) => {

              decline(event, row.id);
            }}
          >
            Decline
          </Button>
        </div>
      ),
      width: "250px",
      sortable: true,
    },
  ];

  return (
    <>
      <Card className="mt-12">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Withdraw Request</h2>
        </CardHeader>
        <CardBody>
        <div className="flex justify-between">
            <div className="mb-6 flex gap-4">
              <Link to={"/money_management/create_withdraw_request"}>
                <Button
                  color="lightBlue"
                  buttonType="filled"
                  size="regular"
                  rounded={false}
                  block={false}
                  iconOnly={false}
                  ripple="light"
                >
                  Manual Withdraw
                </Button>
              </Link>
            </div>

            <div className="mb-6 flex gap-4">
              <Select 
                options={options}
                onChange={(choice) => {

                  filterBank(choice.value)
                }} />
            </div>
          </div>
          <DataTable
            columns={columns}
            data={data}
            progressPending={pending}
            paginationRowsPerPageOptions={[20,40,80,160]}
            pagination
          />
        </CardBody>
      </Card>
    </>
  );
}
