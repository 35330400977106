import React, { useContext, useEffect, useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Checkbox from "@material-tailwind/react/Checkbox";
// import Small from "@material-tailwind/react/Small";
import Button from "@material-tailwind/react/Button";
import axiosInstance from "../../config/axios";
import Swal from "sweetalert2";
import refactorStructureData from "../../helpers/refactorDataMenu";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { RootContext } from "../../App";
import SelectRequired from "../../components/RequiredSelect";
import Loading from "../../components/Loading";
const BankAccountEdit = () => {
  const history = useHistory()
  const params = useParams()
  const { state, setState } = useContext(RootContext)
  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const [check, setCheck] = useState(true)
  const [data, setData] = useState()
  const [optionBankGroup, setOptionBankGroup] = useState([])
  const [optionBankMain, setOptionBankMain] = useState([])

  const optionBankType = [{ label: 'Deposit', value: 1 }, { label: 'Withdraw', value: 2 }]

  const submit = (event) => {

    if (typeof data.grup === "string") {
      const bankGroup = optionBankGroup.find((opt) => {

        return opt.label === data.grup
      })
      data.grup = bankGroup.value
    }
    if (typeof data.main === "string") {
      const bankMain = optionBankMain.find((opt) => {

        return opt.label === data.main
      })
      data.main = bankMain.value
    }



    axiosInstance.post('update_bank_account', {
      token: token,
      group: group.wbid,
      ba_id: data.id,
      bg_id: data.grup,
      bm_id: data.main,
      bank_name: data.bnam,
      bank_acc: data.bacc,
      bank_num: data.bnum,
      bank_type: data.btyp,
      isactive: data.actv,
      percentage: parseInt(data.perc),
      limit_receive: parseInt(data.lrec),
    }).then((res) => {
      history.push('/banking/bank_account')
      Swal.fire(
        'Saved!',
        'Your data is saved!',
        'success'
      )
    }).catch((err) => {
      console.log(err.response)
    })
    event.preventDefault()
  }

  const listBankGroup = () => {
    axiosInstance.post('get_bank_group', {
      token: token,
      group: group.wbid
    }).then((res) => {
      setOptionBankGroup(res.data.data.list.map((data) => {
        return { label: data.name, value: data.id }
      }))
    })
  }
  const listBankMain = () => {
    axiosInstance.post('get_bank_main', {
      token: token,
      group: group.wbid
    }).then((res) => {
      setOptionBankMain(res.data.data.list.map((data) => {
        return { label: data.name, value: data.id }
      }))
    })
  }

  const getDetailAccount = () => {

    axiosInstance("get_bank_account_detail", {
      method: "POST",
      data: {
        token: token,
        group: group.wbid,
        ba_id: params.id
      },
    })
      .then((res) => {
        const lrec = res.data.data.list[0].lrec
        res.data.data.list[0].lrec = lrec.replaceAll('.', '')
        setData({ ...res.data.data.list[0] })
        setState({
          ...state,
          group: res.data.data.additional_data.group,
          menu: refactorStructureData(res.data.data.additional_data.menu),
        });
      })
      .catch((err) => {

      });

  };




  useEffect(() => {
    listBankGroup()
    listBankMain()
    getDetailAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {!data ? <Loading /> :
        (
          <div className="container mx-auto max-w-full">
            <div className="grid grid-cols-1 px-4 mb-16">
              <Card>
                <CardHeader color="blueGray" contentPosition="left">
                  <h2 className="text-white text-2xl">Bank Account</h2>
                </CardHeader>
                <form onSubmit={submit}>
                  <CardBody>
                    <Input
                      color="lightBlue"
                      size="regular"
                      outline={true}
                      value={data.id}
                      placeholder="ID"
                      disabled
                    />
                    <br />
                    <SelectRequired
                      placeholder={data.grup ? data.grup : "BankGroup"}
                      options={optionBankGroup}
                      onChange={(event) => {
                        setData({ ...data, grup: event.value })
                      }}
                      value={optionBankGroup.find((opt) => opt.label === data.grup)}
                    />
                    <br />
                    <SelectRequired options={optionBankMain}
                      placeholder={data.main ? data.main : "Bank Main"}
                      onChange={(event) => {
                        setData({ ...data, main: event.value })
                      }}
                      value={optionBankMain.find((opt) => opt.label === data.main)}
                    />
                    <br />
                    <SelectRequired options={optionBankMain}
                      placeholder={data.bnam ? data.bnam : "Bank Name"}
                      onChange={(event) => {
                        setData({ ...data, bnam: event.label })
                      }}
                      value={optionBankMain.find((opt) => opt.label === data.bnam)}
                    />
                    <br />
                    <Input
                      type="text"
                      color="lightBlue"
                      size="regular"
                      outline={true}
                      placeholder="Bank Account"
                      onChange={(event) => setData({ ...data, bacc: event.target.value })}
                      required
                      defaultValue={data.bacc}
                    />
                    <br />
                    <Input
                      type="text"
                      color="lightBlue"
                      size="regular"
                      outline={true}
                      placeholder="Bank number"
                      onChange={(event) => setData({ ...data, bnum: event.target.value })}
                      required
                      defaultValue={data.bnum}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <br />
                    <SelectRequired options={optionBankType} required
                      placeholder={data.btyp === 1 ? "Deposit" : "Withdraw"}
                      onChange={(event) => setData({ ...data, btyp: event.value })}
                      value={optionBankType.find((opt) => opt.value === data.btyp)}
                    />
                    <br />
                    <Input
                      type="text"
                      color="lightBlue"
                      size="regular"
                      outline={true}
                      placeholder="Percentage"
                      onChange={(event) => setData({ ...data, perc: event.target.value })}
                      required
                      defaultValue={data.perc}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />

                    <br />
                    <Input
                      type="text"
                      color="lightBlue"
                      size="regular"
                      outline={true}
                      placeholder="Limit Receive"
                      onChange={(event) => setData({ ...data, lrec: event.target.value })}
                      defaultValue={data.lrec}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <br />
                    <Input
                      type="text"
                      color="lightBlue"
                      size="regular"
                      outline={true}
                      placeholder="Limit Current"
                      disabled
                      defaultValue={data.lcur}
                    />
                    <br />
                    <Checkbox checked={data.actv ? true : false} onChange={(event) => {
                      setCheck(!check)
                      if (check) {
                        setData({ ...data, actv: 0 })
                      } else {
                        setData({ ...data, actv: 1 })
                      }
                    }} color="lightBlue" text="Active" id="checkbox" />

                  </CardBody>

                  <Button
                    color="green"
                    buttonType="filled"
                    size="regular"
                    rounded={false}
                    block={false}
                    iconOnly={false}
                    ripple="light"
                  >
                    Save
                  </Button>
                </form>

              </Card>
            </div>
          </div>
        )
      }

    </div >
  );
};

export default BankAccountEdit;
