/*
* komponen ini menggunakan material tailwind, jika ingin mengubah atau menambah
* lihat halaman ini sebagai referensi
* https://www.material-tailwind.com/docs/react/installation
*
*/

import { RootContext } from '../../App';
import axiosInstance from '../../config/axios';
import refactorStructureData from '../../helpers/refactorDataMenu';
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import InputIcon from "@material-tailwind/react/InputIcon";
import Select from 'react-select'
import { useDebouncedCallback } from 'use-debounce';
import TableLotteryTransactionHistory from '../../components/TableLotteryTransactionHistory';

export default function LotteryTransactionHistory() {

    const [listData, setListData] = useState();
    const [extraData, setExtraData] = useState({ "fourd": 0, "trid": 0, "twod": 0, });
    const [pending, setPending] = useState(false);
    const [username, setUsername] = useState();
    const [periode, setPeriode] = useState();
    const [number, setNumber] = useState();
    const [type, setType] = useState();
    const [unit, setUnit] = useState();
    const { state, setState } = useContext(RootContext);
    const history = useHistory();
    const group = JSON.parse(localStorage.getItem("group"));

    const debounced = useDebouncedCallback(
        (action) => {
            action()
        },
        1000
    );

    const optionselect = [
        { value: '4d', label: '4D' },
        { value: '3d', label: '3D' },
        { value: '2d', label: '2D' }
    ];

    const optionsunit = [
        { value: 'singapore', label: 'Singapore' },
        { value: 'hongkong', label: 'Hongkong' },
        { value: 'sydney', label: 'Sydney' }
    ];

    const getData = () => {

        setPending(true);

        const token = localStorage.getItem("access_token");

        const data = {

            token: token,
            group: group.wbid,
            username: username,
            periode: periode,
            number: number,
            type: type,
            unit: unit,
        }

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                axiosInstance('/lottery/transaction-history', {

                    method: "POST",

                    data,

                }).then((res) => {

                    if (res.data.code === 0) {

                        const data = res.data.data.list;
                        const exdata = res.data.data.extra_data;

                        setListData(data);
                        setExtraData(exdata);

                        setState({

                            ...state,

                            group: res.data.data.additional_data.group,

                            menu: refactorStructureData(res.data.data.additional_data.menu),

                        });

                        setPending(false);

                    } else {

                        Swal.fire(
                            'Fetch Data Failed',
                            'Please Refresh',
                            'warning'
                        )
                    }



                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        )
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                )

            }

        }

    };

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, periode, number, type, unit]);

    return (
        <>
            <Card>
                <h5>Search Transaction</h5>
                <CardBody>
                    <div className="flex gap-4">
                        <InputIcon
                            type="text"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="Username"
                            iconName="search"
                            onChange={(event) => {

                                debounced(() => setUsername(event.target.value))
                            }
                            }
                        />
                        <InputIcon
                            type="text"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="Periode"
                            iconName="search"
                            onChange={(event) => {

                                debounced(() => setPeriode(event.target.value))
                            }
                            }
                        />
                        <InputIcon
                            type="text"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="Number"
                            iconName="search"
                            onChange={(event) => {

                                debounced(() => setNumber(event.target.value))
                            }
                            }
                        />
                    </div>
                    <div className="flex gap-4 mt-4 " >
                        <div style={{ width: '30%' }}>
                            <Select
                                onChange={(e) => {

                                    setUnit(e.value)
                                }}
                                placeholder="Select a unit"
                                options={optionsunit}

                            />
                        </div>
                        <div style={{ width: '30%' }}>
                            <Select
                                onChange={(e) => {

                                    setType(e.value)
                                }}
                                placeholder="Select a type"
                                options={optionselect}

                            />
                        </div>
                    </div>

                    <div className="flex gap-4 mt-4 " >
                        <Button >Export To CSV</Button>
                    </div>
                </CardBody>
            </Card>

            <Card className="mt-10">
                <CardHeader color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Transaction List History</h2>
                    <div className='flex flex-row'>
                        <div style={{ marginRight: 10 }}>
                            [4D : {extraData.fourd}]
                        </div>
                        <div style={{ marginRight: 10 }}>
                            [3D : {extraData.trid}]
                        </div>
                        <div>
                            [2D : {extraData.twod}]
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <TableLotteryTransactionHistory
                        data={listData}
                        pending={pending}
                    />
                </CardBody>
            </Card >


        </>
    );

}
